package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.esg.EsgMainComponent
import com.ecosave.watch.portal.components.esg.EsgReportSummaryComponent
import com.ecosave.watch.portal.models.esg.ActivitiesAndWorkersState
import com.ecosave.watch.portal.models.esg.AntiCompetitiveBehaviorState
import com.ecosave.watch.portal.models.esg.CustomerHealthAndSafetyState
import com.ecosave.watch.portal.models.esg.CustomerPrivacyState
import com.ecosave.watch.portal.models.esg.EconomicPerformanceState
import com.ecosave.watch.portal.models.esg.EsgReportData
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.EsgReportValidationState
import com.ecosave.watch.portal.models.esg.GovernanceState
import com.ecosave.watch.portal.models.esg.IndirectEconomicImpactsState
import com.ecosave.watch.portal.models.esg.MarketPresenceState
import com.ecosave.watch.portal.models.esg.MarketingAndLabelingState
import com.ecosave.watch.portal.models.esg.OccupationalHealthAndSafetyState
import com.ecosave.watch.portal.models.esg.ProcurementPracticesState
import com.ecosave.watch.portal.models.esg.PublicPolicyState
import com.ecosave.watch.portal.models.esg.ReportCoverInfoState
import com.ecosave.watch.portal.models.esg.ReportFinancialYear
import com.ecosave.watch.portal.models.esg.ReportFinancialYearValidationState
import com.ecosave.watch.portal.models.esg.StakeholderEngagementState
import com.ecosave.watch.portal.models.esg.StrategyPoliciesPracticesState
import com.ecosave.watch.portal.models.esg.SupplierSocialAssessmentState
import com.ecosave.watch.portal.models.esg.TaxState
import com.ecosave.watch.portal.models.esg.TheOrgAndItsRepPracticesState
import react.FC
import react.Props
import react.useState


val EsgSummaryPage = FC<Props> {
    val (summaryOrMainComponent, setSummaryOrMainComponent) = useState(false)
    val (pageNumber, setPageNumber) = useState(0)
    val (esgReportValidation, setEsgReportValidation) = useState(EsgReportValidationState(reportFinancialYear = ReportFinancialYearValidationState()))
    val (esgReportState, setEsgReportState) = useState(
        EsgReportState(
            reportFinancialYear = ReportFinancialYear(),
            report = EsgReportData(
                theOrgAndItsRepPractices = TheOrgAndItsRepPracticesState(),
                activitiesAndWorkers = ActivitiesAndWorkersState(),
                governance = GovernanceState(),
                strategyPoliciesPractices = StrategyPoliciesPracticesState(),
                stakeholderEngagement = StakeholderEngagementState(),
                economicPerformance = EconomicPerformanceState(),
                indirectEconomicImpacts = IndirectEconomicImpactsState(),
                procurementPractices = ProcurementPracticesState(),
                antiCompetitiveBehavior = AntiCompetitiveBehaviorState(),
                reportCoverInfo = ReportCoverInfoState(),
                tax = TaxState(),
                occupationalHealthAndSafety = OccupationalHealthAndSafetyState(),
                marketPresence = MarketPresenceState(),
                customerHealthAndSafety = CustomerHealthAndSafetyState(),
                publicPolicy = PublicPolicyState(),
                marketingAndLabeling = MarketingAndLabelingState(),
                customerPrivacy = CustomerPrivacyState(),
                supplierSocialAssessment = SupplierSocialAssessmentState()
            )
        )
    )

    if (summaryOrMainComponent) {
        EsgMainComponent {
            esgReportStateProps = esgReportState
            esgReportStateSetterProps = setEsgReportState
            esgReportValidationStateProps = esgReportValidation
            esgReportValidationStateSetterProps = setEsgReportValidation
            esgReportSummarySwitchStateSetterProps = setSummaryOrMainComponent
            esgReportPageNumber = pageNumber
            esgReportPageNumberSetter = setPageNumber
        }
    } else {
        EsgReportSummaryComponent {
            esgReportStateProps = esgReportState
            esgReportStateSetterProps = setEsgReportState
            esgReportIsClickedStateProps = summaryOrMainComponent
            esgReportIsClickedStateSetterProps = setSummaryOrMainComponent
            esgReportPageNumber = pageNumber
            esgReportPageNumberSetter = setPageNumber
        }
    }
}