package com.ecosave.watch.portal.components.esg.customerprivacy

import com.ecosave.watch.portal.helpers.common.isValidLongNumber
import com.ecosave.watch.portal.helpers.esg.CustomerPrivacyField
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.CustomerPrivacyState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

external interface CustomerPrivacyComponentProps : Props {
    var reportState: EsgReportState
    var setReportState: StateSetter<EsgReportState>
    var setInputState: StateSetter<CurrentInputState>
    var onBlurApiCall: (String, JsonPrimitive, Int?, String?, String) -> Unit
}

val CustomerPrivacyComponent = FC<CustomerPrivacyComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.customerPrivacy?.let { state ->

        fun stateSetter(state: CustomerPrivacyState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        customerPrivacy = state
                    )
                )
            )
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = CustomerPrivacyField.valueOf(target.name)
                val propertyValue = target.value.toLongOrNull()

                stateSetter(
                    state.copy(
                        section4181A1 = if (propertyName == CustomerPrivacyField.SECTION_418_1_A1) propertyValue else state.section4181A1,
                        section4181A2 = if (propertyName == CustomerPrivacyField.SECTION_418_1_A2) propertyValue else state.section4181A2,
                        section4181A3 = if (propertyName == CustomerPrivacyField.SECTION_418_1_A3) propertyValue else state.section4181A3,

                        section4181B1 = if (propertyName == CustomerPrivacyField.SECTION_418_1_B1) propertyValue else state.section4181B1,
                        section4181B2 = if (propertyName == CustomerPrivacyField.SECTION_418_1_B2) propertyValue else state.section4181B2,
                        section4181B3 = if (propertyName == CustomerPrivacyField.SECTION_418_1_B3) propertyValue else state.section4181B3,
                    )
                )
                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName.fieldName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.CUSTOMER_PRIVACY.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = CustomerPrivacyField.valueOf(target.name)
                val propertyValue = target.value.toLongOrNull()
                props.onBlurApiCall(
                    propertyName.fieldName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    EsgSection.CUSTOMER_PRIVACY.name
                )
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = CustomerPrivacyField.valueOf(target.name)
            val propertyValue = target.value

            stateSetter(
                state.copy(
                    section4181A4 = if (propertyName == CustomerPrivacyField.SECTION_418_1_A4) propertyValue else state.section4181A4,
                    section4181B4 = if (propertyName == CustomerPrivacyField.SECTION_418_1_B4) propertyValue else state.section4181B4
                )
            )

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName.fieldName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.CUSTOMER_PRIVACY.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = CustomerPrivacyField.valueOf(target.name)
            val propertyValue = target.value
            props.onBlurApiCall(propertyName.fieldName, JsonPrimitive(propertyValue), changeIndex, field, EsgSection.CUSTOMER_PRIVACY.name)
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            Section418_1 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
                this.onBlurTextArea = onBlurTextArea
                this.onChangeNumber = onChangeNumber
                this.onBlurNumber = onBlurNumber
            }
        }
    }
}