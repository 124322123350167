package com.ecosave.watch.portal.components.esg.suppliersocialassessment

import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.esg.SupplierSocialAssessmentField
import com.ecosave.watch.portal.models.esg.SupplierSocialAssessmentState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.html.HTMLDivElement
import web.html.HTMLElement

external interface Section4141And2Props : Props {
    var state: SupplierSocialAssessmentState
    var onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
    var onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
    var onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
}

val Section414_1_And_2 = FC<Section4141And2Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber
    val state = props.state

    SectionMainTitle {
        title = "414-1 NEW SUPPLIERS THAT WERE SCREENED USING SOCIAL CRITERIA"
    }

    Box {
        label {
            +"Percentage of new suppliers that were screened using social criteria."
        }
        ESGNumberFieldWrapper {
            name = SupplierSocialAssessmentField.SECTION_414_1_A1.name
            value = state.section4141A1
            onChange = { event -> onChangeDecimalNumber(event, null, null) }
            onBlur = { event -> onBlurDecimalNumber(event, null, null) }
        }
    }

    SectionMainTitle {
        title = "414-2 NEGATIVE SOCIAL IMPACTS IN THE SUPPLY CHAIN AND ACTIONS TAKEN"
    }

    Box {
        className = ESGCommonStyles.GRID_WRAPPER.cssClass

        Box {
            label {
                +"Number of suppliers assessed for social impacts."
            }
            ESGNumberFieldWrapper {
                name = SupplierSocialAssessmentField.SECTION_414_2_A1.name
                value = state.section4142A1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
        }
        Box {
            label {
                +"Number of suppliers identified as having significant actual and potential negative social impacts."
            }
            ESGNumberFieldWrapper {
                name = SupplierSocialAssessmentField.SECTION_414_2_A2.name
                value = state.section4142A2
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
        }
        Box {
            label {
                +"What are the significant actual and potential negative social impacts identified in the supply chain?"
            }
            ESGTextAreaFieldWrapper {
                name = SupplierSocialAssessmentField.SECTION_414_2_A3.name
                value = state.section4142A3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Percentage of suppliers identified as having significant actual and potential negative social impacts with which improvements were agreed upon as a result of assessment."
            }
            ESGNumberFieldWrapper {
                name = SupplierSocialAssessmentField.SECTION_414_2_A4.name
                value = state.section4142A4
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }
        Box {
            label {
                +"Percentage of suppliers identified as having significant actual and potential negative social impacts with which relationships were terminated as a result of assessment, and why?"
            }
            ESGNumberFieldWrapper {
                name = SupplierSocialAssessmentField.SECTION_414_2_A5.name
                value = state.section4142A5
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }
    }
}