package com.ecosave.watch.portal.models.esg
import kotlinx.serialization.Serializable

@Serializable
data class SupplierSocialAssessmentState(
    var section4141A1: Double? = null,

    var section4142A1: Long? = null,
    var section4142A2: Long? = null,
    var section4142A3: String? = null,
    var section4142A4: Double? = null,
    var section4142A5: Double? = null
)