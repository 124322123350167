package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.helpers.esg.TOCSectionsEnum
import com.ecosave.watch.portal.models.esg.EsgReportState
import mui.icons.material.ExpandMore
import mui.material.Accordion
import mui.material.AccordionDetails
import mui.material.AccordionSummary
import mui.material.Box
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import react.create
import react.useState
import web.cssom.px
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles as styles

external interface TableOfContentsComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
    var tocClickedSection: (TOCSectionsEnum) -> Unit
}

val TableOfContentsComponent = FC<TableOfContentsComponentProps> { props ->

    val mainTableOfContent by useState(props.esgReportStateProps.mainTableOfContents)

    Box {
        className = styles.MAIN_ESG_FORM.cssClass
        mainTableOfContent?.enrichedTableOfContents?.forEach { enrichedTableOfContent ->
            Accordion {
                AccordionSummary {
                    expandIcon = ExpandMore.create {}
                    Typography {
                        sx {
                            marginTop = 10.px
                        }
                        variant = TypographyVariant.h6
                        +enrichedTableOfContent.mainTitleStatusTracker.esgSection.description
                    }
                }
                enrichedTableOfContent.enrichedSubContents.forEach { enrichedSubContent ->
                    if (enrichedSubContent.subTitleStatusTracker?.subSection != null) {
                        AccordionSummary {
                            Typography {
                                sx {
                                    marginTop = 10.px
                                }
                                className = styles.TOC_ICON_SECOND.cssClass
                                variant = TypographyVariant.h6
                                onClick = {
                                    props.tocClickedSection(TOCSectionsEnum.valueOf(enrichedSubContent.subTitleStatusTracker.subSection))
                                }
                                +enrichedSubContent.subTitleStatusTracker.subTitle
                            }
                        }
                        AccordionDetails {
                            sx {
                                paddingLeft = 50.px
                            }
                            enrichedSubContent.itemStatusTrackerList.forEach { item ->
                                Typography {
                                    variant = TypographyVariant.body1
                                    +item.item
                                }
                            }
                        }
                    } else {
                        AccordionDetails {
                            sx {
                                paddingLeft = 50.px
                            }
                            enrichedSubContent.itemStatusTrackerList.forEach { item ->
                                Typography {
                                    className = styles.TOC_ICON_SECOND.cssClass
                                    variant = TypographyVariant.body1
                                    +item.item
                                    onClick = {
                                        props.tocClickedSection(TOCSectionsEnum.valueOf(enrichedTableOfContent.mainTitleStatusTracker.esgSection.name))
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}