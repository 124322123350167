package com.ecosave.watch.portal.components.esg.suppliersocialassessment

import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.common.isValidLongNumber
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.SupplierSocialAssessmentField
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.SupplierSocialAssessmentState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

external interface SupplierSocialAssessmentComponentProps : Props {
    var reportState: EsgReportState
    var setReportState: StateSetter<EsgReportState>
    var setInputState: StateSetter<CurrentInputState>
    var onBlurApiCall: (String, JsonPrimitive, Int?, String?, String) -> Unit
}

val SupplierSocialAssessmentComponent = FC<SupplierSocialAssessmentComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.supplierSocialAssessment?.let { state ->

        fun stateSetter(state: SupplierSocialAssessmentState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        supplierSocialAssessment = state
                    )
                )
            )
        }

        val onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement

            if (isValidDecimalNumber(target)) {
                val propertyName = SupplierSocialAssessmentField.valueOf(target.name)
                val propertyValue = target.value.toDoubleOrNull()

                stateSetter(
                    state.copy(
                        section4141A1 = if (propertyName == SupplierSocialAssessmentField.SECTION_414_1_A1) propertyValue else state.section4141A1,
                        section4142A4 = if (propertyName == SupplierSocialAssessmentField.SECTION_414_2_A4) propertyValue else state.section4142A4,
                        section4142A5 = if (propertyName == SupplierSocialAssessmentField.SECTION_414_2_A5) propertyValue else state.section4142A5
                    )
                )

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName.fieldName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.SUPPLIER_SOCIAL_ASSESSMENT.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = SupplierSocialAssessmentField.valueOf(target.name)
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(
                    propertyName.fieldName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    EsgSection.SUPPLIER_SOCIAL_ASSESSMENT.name
                )
            }
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement

            if (isValidLongNumber(target)) {
                val propertyName = SupplierSocialAssessmentField.valueOf(target.name)
                val propertyValue = target.value.toLongOrNull()

                stateSetter(
                    state.copy(
                        section4142A1 = if (propertyName == SupplierSocialAssessmentField.SECTION_414_2_A1) propertyValue else state.section4142A1,
                        section4142A2 = if (propertyName == SupplierSocialAssessmentField.SECTION_414_2_A2) propertyValue else state.section4142A2
                    )
                )

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName.fieldName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.SUPPLIER_SOCIAL_ASSESSMENT.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = SupplierSocialAssessmentField.valueOf(target.name)
                val propertyValue = target.value.toLongOrNull()
                props.onBlurApiCall(
                    propertyName.fieldName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    EsgSection.SUPPLIER_SOCIAL_ASSESSMENT.name
                )
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = SupplierSocialAssessmentField.valueOf(target.name)
            val propertyValue = target.value

            stateSetter(
                state.copy(
                    section4142A3 = if (propertyName == SupplierSocialAssessmentField.SECTION_414_2_A3) propertyValue else state.section4142A3
                )
            )

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName.fieldName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.SUPPLIER_SOCIAL_ASSESSMENT.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = SupplierSocialAssessmentField.valueOf(target.name)
            val propertyValue = target.value
            props.onBlurApiCall(
                propertyName.fieldName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSection.SUPPLIER_SOCIAL_ASSESSMENT.name
            )
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            Section414_1_And_2 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
                this.onBlurTextArea = onBlurTextArea
                this.onChangeNumber = onChangeNumber
                this.onBlurNumber = onBlurNumber
                this.onChangeDecimalNumber = onChangeDecimalNumber
                this.onBlurDecimalNumber = onBlurDecimalNumber
            }
        }
    }
}