package com.ecosave.watch.portal.components.esg.anticompetitivebehavior

import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidLongNumber
import com.ecosave.watch.portal.helpers.esg.AntiCompetitiveBehaviorField
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.failureErrorMessage
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.AntiCompetitiveBehaviorState
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.EsgCollectionDeleteRow
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.LegalActionsOutcomes
import com.ecosave.watch.portal.models.esg.RowPatch
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

external interface AntiCompetitiveBehaviorComponentProps : Props {
    var reportState: EsgReportState
    var setReportState: StateSetter<EsgReportState>
    var setInputState: StateSetter<CurrentInputState>
    var setAddDeleteRowOrSectionApiCallInProgress: StateSetter<Boolean>
    var setAddDeleteRowOrSection: StateSetter<DynamicTableAction>
    var setNotificationMessage: StateSetter<String>
    var setNotificationStatus: StateSetter<NotificationStatus>
    var setOpenAlertNotifications: StateSetter<Boolean>
    var onBlurApiCall: (String, JsonPrimitive, Int?, String?, String) -> Unit
}

val AntiCompetitiveBehaviorComponent = FC<AntiCompetitiveBehaviorComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.antiCompetitiveBehavior?.let { state ->

        fun stateSetter(state: AntiCompetitiveBehaviorState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        antiCompetitiveBehavior = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = EsgSection.ANTI_COMPETITIVE_BEHAVIOR.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )
                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        AntiCompetitiveBehaviorField.SECTION_206_1_LEGAL_ACTIONS_OUTCOMES.fieldName -> {
                            state.legalActionsOutcomesList.add(LegalActionsOutcomes())
                            props.setNotificationMessage("A new row has been added successfully.")
                        }
                    }
                    stateSetter(state)
                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(props.setNotificationMessage, props.setNotificationStatus, props.setOpenAlertNotifications)
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    EsgCollectionDeleteRow(
                        objectPatchEnum = EsgSection.ANTI_COMPETITIVE_BEHAVIOR,
                        reportFileName = reportState.reportFileName,
                        pathFirst = fieldName,
                        indexAt = index
                    )
                )
                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        AntiCompetitiveBehaviorField.SECTION_206_1_LEGAL_ACTIONS_OUTCOMES.fieldName -> {
                            state.legalActionsOutcomesList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }
                    }
                    stateSetter(state)
                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(props.setNotificationMessage, props.setNotificationStatus, props.setOpenAlertNotifications)
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = AntiCompetitiveBehaviorField.valueOf(target.name)
                val propertyValue = target.value.toLongOrNull()

                stateSetter(
                    state.copy(
                        section2061Field1 = if (propertyName == AntiCompetitiveBehaviorField.SECTION_206_1_Field1) propertyValue else state.section2061Field1
                    )
                )
                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName.fieldName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.ANTI_COMPETITIVE_BEHAVIOR.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = AntiCompetitiveBehaviorField.valueOf(target.name)
                val propertyValue = target.value.toLongOrNull()
                props.onBlurApiCall(
                    propertyName.fieldName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    EsgSection.ANTI_COMPETITIVE_BEHAVIOR.name
                )
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = AntiCompetitiveBehaviorField.valueOf(target.name)
            val propertyValue = target.value

            if (field == AntiCompetitiveBehaviorField.SECTION_206_1_LEGAL_ACTIONS_OUTCOMES.fieldName && changeIndex != null) {
                state.legalActionsOutcomesList[changeIndex].section2061A1 =
                    if (propertyName == AntiCompetitiveBehaviorField.SECTION_206_1_A1) propertyValue else state.legalActionsOutcomesList[changeIndex].section2061A1
                state.legalActionsOutcomesList[changeIndex].section2061B1 =
                    if (propertyName == AntiCompetitiveBehaviorField.SECTION_206_1_B1) propertyValue else state.legalActionsOutcomesList[changeIndex].section2061B1
            }

            stateSetter(state)
            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName.fieldName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.ANTI_COMPETITIVE_BEHAVIOR.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = AntiCompetitiveBehaviorField.valueOf(target.name)
            val propertyValue = target.value
            props.onBlurApiCall(propertyName.fieldName, JsonPrimitive(propertyValue), changeIndex, field, EsgSection.ANTI_COMPETITIVE_BEHAVIOR.name)
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            Section206_1 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
                this.onBlurTextArea = onBlurTextArea
                this.onChangeNumber = onChangeNumber
                this.onBlurNumber = onBlurNumber
                this.addRowOrSection = addRowOrSection
                this.deleteRowOrSection = deleteRowOrSection
            }
        }
    }
}